<template>
  <div class="membership-committee">
    <div class="membership-committee__input">
      <InputSearch header="Выберите комитет" placeholder="Медиацентр" />
    </div>
    <Button class="membership-committee__btn">Отправить заявку</Button>
  </div>
</template>

<script>
import InputSearch from "@/components/Blocks/InputSearch";
import Button from "@/components/Blocks/Button";
export default {
  name: "MembershipCommittee",
  components: { Button, InputSearch },
};
</script>

<style lang="scss">
.membership-committee {
  &__input {
    max-width: 730px;
  }
  &__btn {
    margin-top: 5px;
  }
}
</style>
