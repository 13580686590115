<template>
  <div
    class="input-search"
    :class="[className, { 'input-search--active': listSearchShow }]"
  >
    <span class="input-search__label">
      {{ header }}
    </span>
    <div class="input-search__item">
      <input
        :type="type"
        :id="uniqueId"
        v-model="value"
        :class="value ? 'is-focused' : ''"
        :disabled="disabled"
        :placeholder="placeholder"
        v-maska="mask"
        @blur="listSearchShow = false"
        v-bind="$attrs"
        @click="toggleDropdown"
        @input="listSearchShow = true"
      />
    </div>
    <!--    <label :for="uniqueId">{{ label }}</label>-->
    <div class="input-search__results" v-if="listSearchShow">
      <div class="input-search__results-list">
        <label
          v-for="(item, index) in withFullList ? resultsArray : filteredList"
          :key="index"
          :for="`dropdownItem${index}_${uniqComponentId}`"
          @mousedown="select(item)"
        >
          <input
            type="radio"
            :id="`dropdownItem${index}_${uniqComponentId}`"
            :value="item.value"
            v-model="value"
            name="dropdownItem"
          />
          {{ item.title ?? item.title_full }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import { maska } from "maska";
import { mixin as VueClickAway } from "vue3-click-away";

export default {
  name: "InputSearch",
  components: {},
  mixins: [VueClickAway],
  directives: { maska },
  data() {
    return {
      uniqueId: uniqueString(),
      uniqComponentId: uniqueString(),
      listSearchShow: false,
      isFocused: false,
      withFullList: true,
    };
  },
  props: {
    header: [String, Boolean],
    placeholder: [String, Boolean],
    label: String,
    modelValue: [String, Number],
    type: String,
    error: Boolean,
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorArray: Array,
    mask: String,
    resultsArray: Array,
  },
  watch: {
    modelValue(value) {
      if (value?.length > 0) this.withFullList = false;
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "input-search--error": this.error,
        "input-search--disabled": this.disabled,
      };
    },
    filteredList() {
      if (!this.value || !/[^\s]+/.test(this.value)) {
        return this.resultsArray;
      } else {
        return this.resultsArray.filter((post) => {
          if (post.title) {
            return post.title
              ?.toLowerCase()
              .includes(this.value?.toLowerCase());
          }
          if (post.title_short || post.title_full) {
            return (
              post.title_short
                ?.toLowerCase()
                .includes(this.value?.toLowerCase()) ||
              post.title_full?.toLowerCase().includes(this.value?.toLowerCase())
            );
          }
        });
      }
    },
  },
  methods: {
    select(item) {
      this.value = item.title ?? item.title_full;
      this.listSearchShow = false;
      this.$emit("form-item", item);
      this.withFullList = true;
    },
    toggleDropdown() {
      this.withFullList = true;
      this.listSearchShow = !this.listSearchShow;
    },
  },
};
</script>

<style lang="scss">
.input-search {
  display: block;
  margin-bottom: 11px;
  appearance: none;
  outline: none;
  position: relative;
  &.input {
    grid-gap: 0;
  }
  &--disabled {
    .input-search__item {
      background: var(--background--disabled);
      pointer-events: none;
    }
  }
  &__label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  &__item {
    transition: all 0.3s ease;
    margin-top: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    border: 1px solid var(--input--border);
    border-radius: 8px;
    outline: none;
    &::after {
      content: "\e90f";
      background-size: contain;
      font-family: icomoon;
      display: block;
      color: var(--color--disabled);
      font-size: 1rem;
      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);
    }
    & input {
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    &:hover,
    &:focus {
      border-color: var(--background--main);
    }
  }
  &--active {
    .input-search__item {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
  &__results {
    overflow: hidden;
    display: block;
    position: absolute;
    z-index: 1000;
    width: 100%;
    margin: 10px 0 0 0;
    background-color: var(--white);
    border: 1px solid var(--background--main);
    border-radius: 8px;
    box-shadow: 0px 8px 20px rgba(24, 24, 24, 0.08);
    &-list {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 250px;
      overflow-scrolling: touch;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
      padding: 10px 0 10px 0;
      @include scrollbar();
      input {
        display: none;
      }

      label {
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        padding: 10px 5px;
        color: black;
        margin: 0 8px;
        border-radius: 8px;
        &:hover {
          background: var(--background--application--review);
        }
      }
      span {
        display: block;
        padding: 10px 15px;
      }
    }
  }
  &--error {
    position: relative;
    .input-search__item {
      border-color: var(--red);
    }
  }
}
</style>
